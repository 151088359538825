import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    profile:{},
    username:"",
    token:"",
   
    config: {},
    tokenKeys: {
      CACHE_KEY_TOKEN: "sun-token",
      CACHE_KEY_PROFILE: "sun-profile",
    },
    menu: {
      open: true,
      fixMenu:false,
      //disableMenu:false
    },
    shops:[]
  },
  mutations: {
    fixMenu(state, yesOrNo) {
      state.menu.fixMenu = yesOrNo
    },
    setShops(state, shops) {
      state.shops = shops
    },
    disableMenu(state, yesOrNo) {
      //state.menu.disableMenu = yesOrNo
    },
    setMenu(state, menuState) {
      //console.log("commiting to store", menuState)
      state.menu.open = menuState ? menuState : !state.menu.open
    },
    setConfig(state, conf) {
      //console.log("Config updated in store", conf)
      state.config = conf
    },
    updateProfile(state, profile){
      state.profile = profile
    },
    updateUsername(state, username) {
      state.username = username
    },
    updateToken(state,token){
      state.token = token
    },
    logout: state => {
      state.username = "";
      state.profile = {};
      state.token = ""
    }
  },
  getters: {
    config: state => {
      let config = JSON.parse(JSON.stringify(state.config))
      return config
    },
    tokenKeys: state => {
      let tokenKeys = JSON.parse(JSON.stringify(state.tokenKeys))
      return tokenKeys
    },
    shops: state => {
      return state.shops
    },
    username:state=>{
      if (state.username){
        return state.username
      }
      return null
    },
    token: state=>{
      return state.token
    }
  },
});
