class Cache{
  
  localStore:any

  constructor(){
    console.log("new instance of Cache")
    if (window.localStorage) {
      try {
        this.localStore = window.localStorage
      } catch(e) {
        console.log("Local storage not supported on this browser. May need to use cookies")
      }
    }
  }

  cacheEx(key:string, value:any, expireInSeconds:number){
    if(this.localStore){
      let toStr = JSON.stringify(value)
      this.localStore.setItem(key, toStr)


      var start = new Date().getTime();
      let ttl = (start + expireInSeconds*1000) 
      this.localStore.setItem(key+"_ex",ttl)
    }
    else{
      console.log("No local storage. cant store value")
    }
  }
  cache(key:string, value:any){
    if(this.localStore){
      let toStr = JSON.stringify(value)
      this.localStore.setItem(key, toStr)
    }
    else{
      console.log("No local storage. cant store value")
    }
  }
  
  remove(key: string) {
    if (this.localStore) {
      this.localStore.removeItem(key)
      this.localStore.removeItem(key + '_ex')
    }
    else {
      console.log("No local storage. cant remove value")
    }
  }
  
  cached(key: string) {
    if(this.localStore){
      let fromStr = this.localStore.getItem(key)
      if(fromStr){
        let ex = this.localStore.getItem(key+"_ex")
        if(ex){
          var start = new Date().getTime();
          if(start > ex) {
            this.localStore.removeItem(key)
            this.localStore.removeItem(key+"_ex")
            console.log("expired cache key", key)
            return null
          }
        }
        return JSON.parse(fromStr)
      }
      return null
    }
    else{
      return null
    }
  }
}

export const cacheSrv = new Cache()
