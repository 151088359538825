import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { systemSrv } from './services/system'

let config = require(`./config.${process.env.NODE_ENV}.json`)

Vue.config.productionTip = false;

import VueSnackbar from 'vue-snack' 
Vue.use(VueSnackbar, {})

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

import Analytics from 'vue-analytics'
Vue.use(Analytics, {
  id: 'UA-146135061-1',
  // debug: {
  //   sendHitTask: process.env.NODE_ENV === 'production'
  // },
  router
})

// External function in module
let vueCreatedB = false

async function initializeApp() {
  if(!vueCreatedB){
    vueCreatedB = true
    store.commit('setConfig', config)
    console.log("Initialising tokens, profiles, and other necessary system components before proceeding to mount the app")
    await systemSrv.initUser()
  }
}

initializeApp().then(_ => {
  new Vue({
    router,
    store,
    data: {
      brand: {}
    },
    async created() {
      this.$mount('#app')
      console.log("System Initialised. App mounted!")
    },
    render: (h) => h(App),
  }).$mount('#app');

})
