
import { Component, Vue } from 'vue-property-decorator';
import store from "./store";

@Component({
  components: {},
})
export default class App extends Vue {
  constructor(){
    super()
    this.initializeState()
  }
  created(){}
  
  async initializeState(){
  }
}
