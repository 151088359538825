import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import store from './store';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { layout: "default" },
      component: () => import(/* webpackChunkName: "users" */ './views/Welcome.vue')
      //component: () => import(/* webpackChunkName: "users" */ './views/Welcome.vue')
    },
    {
      path: '/start',
      name: 'start',
      meta: { layout: "default" },
      component: () => import(/* webpackChunkName: "users" */ './views/Start.vue')
    },
    {
      path: '/preferences',
      name: 'preferences',
      meta: { layout: "default" },
      component: () => import(/* webpackChunkName: "users" */ './views/Preferences.vue')
    },
    {
      path: '/spin',
      name: 'spin',
      meta: { layout: "default" },
      component: () => import(/* webpackChunkName: "users" */ './views/Spin.vue')
    },
    { path: '*', redirect: '/' }
  ],
});

export default router