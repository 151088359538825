import store from "./../store"
import {cacheSrv} from "./cache"
import {notiSrv} from "./noti"
import router from "@/router"
import axios from "axios"

class System {
  memberAPIUrl
  profile = {}
  token
  CACHE_KEY_PROFILE = store.getters.tokenKeys.CACHE_KEY_PROFILE
  CACHE_KEY_TOKEN = store.getters.tokenKeys.CACHE_KEY_TOKEN

  constructor() {}

  //Must make sure that all calls in this function to async
  //subroutines must do "await" to load and complete their
  //work before proceeding wiring the app
  async initUser(force = false) {
    // need to have it here to ensure config is setup before init
    this.memberAPIUrl = store.getters.config.memberAPIUrl
    console.log("Preparing member profile")
    this.token = cacheSrv.cached(this.CACHE_KEY_TOKEN)
    if (!this.token) {
      console.log("No token found. redirecting to login")
      router.push("login")
      return
    } else {
      store.commit(`updateToken`, this.token)
      let profile = cacheSrv.cached(this.CACHE_KEY_PROFILE)
      if (force || !profile || !profile.name) {
        console.log("No profile cached. Load from server")
        await this.loadUserProfile()
      } else {
        if (profile && profile.dob) {
          profile["dob"] = new Date(profile["dob"]).toUTCString()
          console.log("Date fixed: profile.dob", profile.dob)
        }
        this.profile = profile
        store.commit(`updateProfile`, this.profile)
      }
    }
  }

  async updateMemberDynamicFields(payload) {
    this.token = cacheSrv.cached(this.CACHE_KEY_TOKEN)
    await this.loadUserProfile()
    console.log("this.token", this.token)
    console.log("this.profile", this.profile)
    if (this.token && this.profile && this.profile["mobileNo"]) {
      let result
      var config = {
        headers: {Authorization: "bearer " + this.token},
      }
      try {
        payload["mobileNo"] = this.profile["mobileNo"]
        result = await axios.post(`${this.memberAPIUrl}update-dynamic-fields`, payload, config)
      } catch (error) {
        if (error instanceof Error) {
          console.log("Error while calling dyamic field update api.", error && error["response"])
          var errorData = error["response"] ? error["response"].data : {}
          if (errorData.code) {
            return {error: errorData.meta.error.message}
          } else {
            return {error: "Error while updating dynamic fields"}
          }
        }
      }
      if (result.data) {
        let data = result.data
        if (!data["error"]) {
          if (data["profile"]) {
            //this.storeProfileLocally(data["profile"])
          } else {
            console.log("no dynamic fields udpate of profile happend with SSO")
          }
        }
        return data
      } else {
        console.log("Error in validating username. No data")
      }
    } else {
      console.log("ERROR - Profile not available to extract mobileNo. No dynamic field update happend")
    }
    return
  }

  async updateUserProfile(profile) {
    if (this.token) {
      let result
      var config = {
        headers: {Authorization: "bearer " + this.token},
      }
      try {
        result = await axios.post(`${this.memberAPIUrl}update`, profile, config)
      } catch (error) {
        if (error instanceof Error) {
          console.log("Error while calling update profile api.", error["response"])
          var errorData = error["response"] ? error["response"].data : {}
          if (errorData.code) {
            return {error: errorData.meta.error.message}
          } else {
            return {error: "Error while updating profile"}
          }
        }
      }
      if (result.data) {
        let data = result.data
        if (!data["error"]) {
          if (data["profile"]) {
            this.storeProfileLocally(data["profile"])
          } else {
            console.log("no update of profile happend with SSO")
          }
        }
        return data
      } else {
        console.log("Error in validating username. No data")
      }
    }
    return
  }
  async loadUserProfile() {
    if (this.token) {
      let result
      var config = {
        headers: {Authorization: "bearer " + this.token},
      }

      try {
        result = await axios.post(`${this.memberAPIUrl}get-profile`, {}, config)
      } catch (e) {
        console.log("Error while authenticating")
        return
      }
      if (result.data) {
        let data = result.data
        if (data["error"]) {
          console.log("Error in validating number")
          return
        }
        if (data["profile"]) {
          this.storeProfileLocally(data["profile"])
        } else {
          console.log("no token")
        }
      } else {
        console.log("Error in validating username. No data")
      }
    }
  }

  qrcodetransparent = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" //transparent
  async loadQRCode(type) {
    if (this.token) {
      let result
      var config = {
        headers: {Authorization: "bearer " + this.token},
      }
      type = type ? type : "standard"
      try {
        result = await axios.get(`${this.memberAPIUrl}qr/` + type, config)
      } catch (e) {
        console.log("Error loading qr")
        return this.qrcodetransparent
      }
      if (result.data) {
        return result.data
      } else {
        return this.qrcodetransparent
      }
    }
  }
  storeProfileLocally(profile) {
    if (profile && profile.dob) {
      profile["dob"] = new Date(profile["dob"]).toISOString()
      console.log("Date fixed: profile.dob", profile.dob)
    }
    this.profile = profile
    cacheSrv.cacheEx(this.CACHE_KEY_PROFILE, this.profile, 60 * 10)
    store.commit(`updateProfile`, this.profile)
  }
}

export const systemSrv = new System()
